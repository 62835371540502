<template>
  <navigation />
  <div v-if="ordersLoader.loading"><Loading /></div>
  <div v-if="ordersLoader.fulfill" class="section_record">
    <div v-if="orders.length === 0" class="empty_record_items">目前無訂單紀錄</div>
    <div v-for="item in orders" :key="item" class="record_item">
      <table class="record_table" cellpadding="0" cellspacing="0" border="0">
        <tr>
          <th class="record_item_title" colspan="3">交易單號：{{ item.OrderCode }}</th>
        </tr>
        <tr>
          <td class="left_cell" width="23%">交易日期</td>
          <td class="center_cell">交易通路</td>
          <td class="right_cell" width="26%">訂單金額</td>
        </tr>
        <tr>
          <td class="left_cell">
            {{ item.OrderDate }}
          </td>
          <td class="center_cell">
            {{ item.OrderStore }}
          </td>
          <td class="right_cell">
            {{ item.SalesAmount.toLocaleString() }}
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="record_tips">消費紀錄將於消費隔日顯示</div>
</template>

<script>
import dayjs from 'dayjs'
import { computed } from 'vue'
import { useStore } from 'vuex'

import Loading from '@/components/Loading.vue'

import navigation from '../components/navigation.vue'
import useUpdateOrders from '../composables/useUpdateOrders'
import { UI_DATE_FORMAT_SHORT } from '../constants'

const formatToUI = (order) => ({
  ...order,
  OrderStore: order.OrderStore === 'ECOM' ? 'Adidas 官網' : order.OrderStore,
  OrderDate: dayjs(order.OrderDate).format(UI_DATE_FORMAT_SHORT)
})

export default {
  name: 'OrderRecordView',
  components: { navigation, Loading },
  setup() {
    const store = useStore()
    const ordersLoader = computed(() => store.state.orders)

    useUpdateOrders()

    const orders = computed(() => {
      const data = store.state.orders.data
      if (data === null) {
        return []
      }
      return data.ListOfSalesOrders.map(formatToUI)
    })

    return { ordersLoader, orders }
  }
}
</script>

<style scoped></style>
